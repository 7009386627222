














































































import { Vue, Component, Prop, mixins, Watch } from 'nuxt-property-decorator';
import { BlokItemSlide, StoryblokAsset } from '../../types';
import BreakpointMixin from '../../mixins/BreakpointMixin';
import MediaHandler from '@/components/utilities/media/MediaHandler';
import LinkManual from '@/components/utilities/links/LinkManual';
import IconHandler from '@/components/utilities/icons/IconHandler';

@Component({
  components: { MediaHandler, LinkManual, IconHandler }
})
export default class ItemSlideVideo extends mixins(BreakpointMixin) {
  @Prop() blok!: BlokItemSlide;
  @Prop() activeSlideIndex!: number;
  @Prop() slideIndex!: number;
  $refs !: { videoRef: Element, videoMobileRef: Element };
  icon: string = require('~/assets/icons/plus.svg');
  is_hovered: boolean = false;
  isPlaying: boolean = false;
  is_hoverbox_hovered: boolean = false;
  observer: IntersectionObserver | null = null;
  get poster (): StoryblokAsset|false {
    return this.blok?.poster ? this.blok.poster : false;
  }
  get posterMobile (): StoryblokAsset|false {
    return this.blok?.poster_mobile ? this.blok.poster_mobile : false;
  }
  get isCurrentlyActive (): boolean {
    return this.activeSlideIndex === this.slideIndex;
  }
  go_to_blogpost (): void {
    if (!this.blok.link) { return; }
    if (!this.blok.link.cached_url) { return; }
    this.$router.push(this.blok.link.cached_url);
  }
  setOverlayColor () {
    if (this.blok.overlay_color === 'none-fontdark') {
      const swiperNavigation = this.$parent.$el.getElementsByClassName('ct-swiper-navigation')[0];
      swiperNavigation.classList.add('color-dark');
    }
  }

  goToNextSlide () {
    this.$emit('slided', this.slideIndex + 1);
    this.$emit('activeAutoplay');
  }

  initIntersectionObserver () {
    const options = {
      root: null,
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.$refs.videoRef?.play()) {
            this.$refs.videoRef.play();
            this.isPlaying = true;
          }
          if (this.$refs.videoMobileRef?.play()) {
            this.$refs.videoMobileRef.play();
            this.isPlaying = true;
          }
        } else {
          if (this.$refs.videoRef?.pause()) {
            this.$refs.videoRef.pause();
            this.isPlaying = false;
          }
          if (this.$refs.videoMobileRef?.pause()) {
            this.$refs.videoMobileRef.pause();
            this.isPlaying = false;
          }
        }
      });
    }, options);

    this.observer.observe(this.$refs.videoRef);
  }

  @Watch('isCurrentlyActive')
  onIsCurrentlyActiveChanged (value: boolean) {
    const swiperNavigation = this.$parent.$el.getElementsByClassName('ct-swiper-navigation')[0];
    if (!swiperNavigation) { return; }
    if (value) {
      this.$emit('deactiveAutoplay');
      swiperNavigation.classList.add('color-dark');
    } else {
      swiperNavigation.classList.remove('color-dark');
    }
  }
  mounted () {
    this.$nextTick(() => {
      if (this.isCurrentlyActive) {
        this.setOverlayColor();
      }
    });
    this.initIntersectionObserver();
  }

  beforeDestroy () {
    if (this.observer) {
      this.observer.disconnect();
    }
    const swiperNavigation = this.$parent.$el.getElementsByClassName('ct-swiper-navigation')[0];
    if (swiperNavigation) {
      swiperNavigation.classList.remove('color-dark');
    }
  }
}
